
  import { Component, Prop } from 'vue-property-decorator'
  import { Expandable } from '@/components/toolkit/details/row/custom/Expandable'
  import ExpandablePerson from '@/components/toolkit/details/row/custom/ExpandablePerson.vue'

@Component({
  components: { ExpandablePerson },
})
  export default class ExpandableMultiplePerson extends Expandable {
  @Prop({ type: Array, default: () => ([]) }) fields: Array<{ path: string, label: string, transform: string | null }>

  // Methods

  // Getters

  // Watchers
  }
